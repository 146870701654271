@import '@/styles/variable';

.section-box {
  margin-top: 20px;

  .section-box-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #000;

    .title-wrapper {
      color: #fff;
      display: flex;
      align-items: center;

      &.required {
        padding-left: 20px;

        &:after {
          content: '*';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          color: $theme_attach_color;;
        }
      }

      .title-main {
        line-height: 1;
      }

      .question {
        margin-left: 4px;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}
