@import "@/styles/mixins";

.fullScreenImage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  .images{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .mainImage {
    flex:1;
    height: 80vh;
    @include bg-common('');
  }
  .otherImageBox {
    margin-top: 20px;
    width: 60vw;
   
    .otherImageWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .otherImage {
        margin-right: 20px;
        width: 10vh;
        height: 10vh;
        cursor: pointer;
        border:1px solid rgba(255,255,255,0.3);
        border-radius: 4px;
        @include bg-common('');
      }
    }
  }
  .closeBox {
    position: absolute;
    right: 20px;
    top: 20px;
    .closeBtn {
      font-size: 28px;
      color: #fff;
      cursor: pointer;
    }
  }
}