.scrollTop {
    position: fixed;
    right: 10px;
    bottom:100px;

    :global {
        .backToTop {
            background-color: #5f5e66;
            color: #fff;

            &:hover {
                background-color: #5f5e66;
            }
        }
    }
}