@import "@/styles/variable";

$headerHeight: 60px;
$minWidth: 1440px;

.layout {
  width: 100%;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $layout_header;
    background: #141415;
    height: $headerHeight;
  }
  .headerSticky {
    @extend .header;
    position: sticky;
  }
  .body {
    min-height: calc(100vh - 165px);
    padding-top: $headerHeight;
    background: #141415;
    color: #fff;
  }
}