
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    background-color: #141415;
    font-size: 14px;
}
a {
    color:#36d4c7;
    text-decoration: none;
}


::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    -webkit-appearance: none;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 1px solid rgba(255,255,255,0.2);
    background-color: rgba(0 0 0 / 0.5);
}

.swiper{
    --swiper-navigation-color: #36d4c7;
  }