@import '@/styles/variable';

.feedbackModal {

    :global {
        .MuiPaper-root {
            width: 640px;
        }
        .MuiDialogContent-root {
            padding-top: 24px;
        }
    }
}