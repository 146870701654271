.footerBar {
  padding: 40px;
  background-color: #262628;
  color: #fff;
  min-width: 350px;
  .footerInner {
    position: relative;
    height: 100%;

    .footerTop {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      .left {
        margin-bottom: 20px;
        .policy {
          margin-top: 20px;
          .item {
            margin-right: 10px;
          }
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;

        &.rightSmall {
          justify-content: flex-start;
        }

        .rightItem {
          // margin-right: 64px;
          text-align: center;

          .title {
            font-size: 12px;
            font-weight: 700;
            margin-bottom: 12px;
          }

          img {
            margin-bottom: 10px;
            width: 30vw;
            max-width: 100px;
          }

          a {
            color: #fff;
            font-weight: 600;
            font-size: 16px;
          }
        }
      }
    }

    .footerBottom {
      span {
        margin-right: 24px;
      }

      a {
        color: #fff;
      }
    }
  }
}