@import "@/styles/variable";


.navBar {
  position: relative;
  padding:5px 0;
  background-color: #141415;
  color: #fff;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid rgba(255,255,255,0.3);

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
    z-index:-1;
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      width: 50px;
      height: 50px;
    }

    .iconText {
      margin-left: 5px;
      font-size: 14px;
      cursor: pointer;
      .iconTextSpan {
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }

  .linkWrapper {
    display: flex;
    align-items: center;
    .link {
      margin-left: 20px;
      color: #fff
    }
    .linkActive {
      @extend .link;
      color: $theme_attach_color;
    }
  }

  .loginWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
      color: #333;
    }
  }

  .menuBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
}

.avatarAndName {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.avatar {
  width: 28px;
  height: 28px;
  object-fit: cover;
  border-radius: 50%;
}

.aiPaintingTimes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  color: #36d4c7;
  font-weight: 600;
  border: 1px solid #514e4e;
  border-radius: 13px;
  padding: 4px 8px;
  cursor: pointer;
  height: 26px;
  transition: all 0.3s;
  max-width: 100px;
  &>img {
    margin-right: 2px;
    width: 18px;
  }
  &:hover {
    border: 1px solid #a6a6a6;
  }
}

.aiModel {
  background: #ec3d3d;
  color: #fff;
    transform: scale(.8);
  border-radius: 20px;
  padding: 4px 6px;
  margin-bottom: 8px;
  font-size: 12px;
}